import { domainPath } from '../App';
import { searchDClient, dynamicSearchClient, dynamicSearchFamily } from '../api/api';

export class KeyUpService {
    static handleKeyUp = async (e, state, data, configType) => {
        const { name, value } = e.target;
        const { client_form, isEdit } = state;
        const {
            'First Name': First_Name,
            'Last Name': Last_Name
        } = client_form;
        const isAccessToken = state.user?.user?.accessToken || '';

        if (isEdit !== 'true') {
            if (['perseus-house'].includes(domainPath)) {
                if (name === 'Date of Birth') {
                    let response = await searchDClient('', '', First_Name, Last_Name, value, isAccessToken, '');
                    if (response.status === 'success' && response.response && response.response.length > 0) {
                        alert(
                            `${data.question} already exists. Data will not save if you continue. Please use the existing client search to update any existing client data.`
                        );
                    }
                }
            } else {
                if (data?.is_primary_key) {
                    let clientCode = client_form[`${data.question}`];                    
                    if(clientCode){
                        let response = [];
                        if(configType === 1) {
                           response = await dynamicSearchClient({ [data.question]: clientCode }, isAccessToken, true);
                        } else {
                           response = await dynamicSearchFamily({ [data.question]: clientCode }, isAccessToken, true);
                        }
                        
                       
                       if (response && response.length > 0) {
                           alert(
                           `${data.question} already exists. Data will not save if you continue. Please use the existing client search to update any existing client data.`
                           )
                       }
                    }
                     
                }
                }
            }
        }
}
